<template>
  <div class="account">
    <div class="department-name">企业微信</div>
    <div class="form-info" v-loading="form.loadingState">
      <el-form ref="formRef" :model="form.data" :rules="rules" label-width="140px" label-position="right">
        <el-form-item label="corpId" prop="corpId" :inline-message="true">
          <el-input
            v-model.trim="form.data.corpId"
            maxlength="36"
            show-word-limit
            :style="inputStyle"
            disabled
            placeholder="请输入corpId"
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定公司" prop="corpName" :inline-message="true">
          <el-input v-model="form.data.corpName" disabled :style="inputStyle" placeholder="请选择绑定公司"></el-input>
        </el-form-item>
        <el-form-item label="员工密钥" prop="employeeSecret" :inline-message="true">
          <el-input
            v-model.trim="form.data.employeeSecret"
            maxlength="64"
            show-word-limit
            :style="inputStyle"
            placeholder="请输入员工密钥"
          ></el-input>
        </el-form-item>
        <el-form-item label="员工token" prop="employeeToken" :inline-message="true">
          <el-input
            v-model.trim="form.data.employeeToken"
            maxlength="36"
            show-word-limit
            disabled
            :style="inputStyle"
            placeholder="请输入员工token"
          ></el-input>
        </el-form-item>
        <el-form-item label="员工AESKey" prop="employeeEncodingAESKey" :inline-message="true">
          <el-input
            v-model.trim="form.data.employeeEncodingAESKey"
            maxlength="64"
            show-word-limit
            disabled
            :style="inputStyle"
            placeholder="请输入员工AESKey"
          ></el-input>
        </el-form-item>
        <el-form-item label="业主密钥" prop="contactSecret" :inline-message="true">
          <el-input
            v-model.trim="form.data.contactSecret"
            maxlength="64"
            show-word-limit
            :style="inputStyle"
            placeholder="请输入业主密钥"
          ></el-input>
        </el-form-item>
        <el-form-item label="业主token" prop="contactToken" :inline-message="true">
          <el-input
            v-model.trim="form.data.contactToken"
            maxlength="36"
            show-word-limit
            disabled
            :style="inputStyle"
            placeholder="请输入业主token"
          ></el-input>
        </el-form-item>
        <el-form-item label="业主AESKey" prop="contactEncodingAESKey" :inline-message="true">
          <el-input
            v-model.trim="form.data.contactEncodingAESKey"
            maxlength="64"
            show-word-limit
            :style="inputStyle"
            disabled
            placeholder="请输入业主AESKey"
          ></el-input>
        </el-form-item>
        <el-form-item label="代理地址" prop="workUrl" :inline-message="true">
          <el-input
            v-model.trim="form.data.workUrl"
            maxlength="64"
            show-word-limit
            :style="inputStyle"
            placeholder="请输入代理地址"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="button-info">
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, computed } from 'vue'
import { saveCorp, getCorpData, updateCorp } from '@/apis/sys'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'enterpriseWeChat',
  setup() {
    const inputStyle = {
      width: '350px',
      'margin-left': '40px'
    }
    const store = useStore()
    const userInfo = computed(() => {
      return store.state.userInfo
    })
    const isUpdate = ref(false)
    const form = reactive({
      data: {
        corpName: userInfo.value.companyName,
        corpId: '',
        id: '',
        khtid: userInfo.value.companyNo,
        corpFullName: userInfo.value.corpFullName,
        employeeSecret: '',
        employeeToken: '',
        employeeEncodingAESKey: '',
        contactSecret: '',
        contactToken: '',
        contactEncodingAESKey: '',
        workUrl: ''
      },
      loadingState: false
    })
    const rules = {
      corpName: { required: true, message: '请选择绑定公司', trigger: 'change' },
      // workUrl: { required: true, message: '请输入代理地址', trigger: 'blur' },
      corpId: { required: true, message: '请输入corpId', trigger: 'blur' },
      employeeToken: { required: true, message: '请输入员工token', trigger: 'blur' },
      employeeEncodingAESKey: { required: true, message: '请输入员工AESKey', trigger: 'blur' },
      contactToken: { required: true, message: '请输入业主token', trigger: 'blur' },
      contactEncodingAESKey: { required: true, message: '请输入业主AESKey', trigger: 'blur' }
      // contactSecret: { required: true, message: '请输入业主密钥', trigger: 'blur' },
      // employeeSecret: { required: true, message: '请输入员工密钥', trigger: 'blur' }
    }
    const getCorpInfo = async () => {
      // 获取商户信息
      form.loadingState = true
      let result = await getCorpData()
      form.loadingState = false
      if (result.code == 200) {
        if (result.data?.corp?.id) {
          isUpdate.value = true
        }
        if (result.data.corp) {
          form.data = result.data.corp
          form.data.corpId = result.data.corp.id
        }
      }
    }

    const formRef = ref(null)
    const onSubmit = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          if ((form.data.employeeSecret || form.data.contactSecret) && !form.data.workUrl) {
            ElMessage.error('请输入代理地址')
            return
          }
          let httpApi = isUpdate.value ? updateCorp : saveCorp
          if (!isUpdate.value) {
            form.data.id = form.data.corpId
          }
          let res = await httpApi(form.data)
          if (res.code == 200) {
            ElMessage.success('操作成功')
            // 操作成功后进行一遍查找操作，防止页面不刷新的情况下再次点击保存就走了新增接口
            getCorpInfo()
          }
        }
      })
    }

    getCorpInfo()
    return {
      inputStyle,
      rules,
      form,
      formRef,
      onSubmit,
      isUpdate
    }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
